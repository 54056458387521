@media (max-width: 1092px) {
    .modal-lg {
        width: auto !important;
        margin: 30px 10px;
    }
};

@media (min-width:768px) {
    .confirm-window .modal-dialog {
        width: 450px !important;
    }
}
@media (max-width:767px) {
    input[type="search"] {
        width: 100% !important;
    }
}